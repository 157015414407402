import React from 'react';

const PegaIcon = () => {
  return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            viewBox="0 0 50 22" style="enable-background:new 0 0 50 22;">
        <g id="Layer_2_1_">
            <g id="Layer_1-2">
                <g>
                    <path class="st0" d="M25.7,3.2c-0.5,2-0.5,4-0.2,6l-1.6-0.3c-11.5-1.8-15,3.8-13.7,8.4C6.7,18.7,3.3,20.2,0,22
                        C4.5,11.2,14.3,1.6,22.6,0.4C25.3,0,26.1,1.8,25.7,3.2z M49.9,11.9l-0.7,1.2c-0.4,0.5-1.1,0.6-1.6,0.3c-0.2-0.1-2.9-1.7-3.9-1.6
                        c-5,0.5-6.4-1.6-6.6-1.8s-0.4-0.1-0.2,0.3s4.4,2.7,4.4,2.7c0.5,1.2,0.8,2.4,0.9,3.6c-0.8-0.7-1.7-1.3-2.7-1.7
                        c-3.8-2.5-7.9-4.3-12.3-5.3c0.3-4.5,2.6-10.9,13.1-9.4c1.2,0.2,2.4,0.7,3.5,1.3L43,2.3c-0.2,0.2-0.2,0.5,0,0.7
                        c0,0,0.1,0.1,0.1,0.1l2.2,1.7c-0.1,0.7,0.2,1.5,0.7,2c0.7,0.9,3.4,3.7,3.8,4.1C50,11.2,50.1,11.6,49.9,11.9L49.9,11.9z M44.6,5.6
                        L44.6,5.6c-0.5-0.2-1-0.1-1.3,0.3h-0.1l1.2,0.5L44.6,5.6z"/>
                    <path class="st1" d="M26.7,13.2c-5.6,0.7-11.2,2.1-16.5,4.1c-1.3-4.6,2.2-10.2,13.7-8.4l1.6,0.3C25.7,10.6,26.1,11.9,26.7,13.2z
                        M27.3,13.1c4.5-0.3,8.7,0.1,12.2,1.8c-3.8-2.5-7.9-4.3-12.3-5.3C27.1,10.8,27.1,11.9,27.3,13.1L27.3,13.1z"/>
                </g>
            </g>
        </g>
        </svg>
  );
};

export default PegaIcon;